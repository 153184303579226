@import "src/styles/common.scss";

.component-container {
    padding-bottom: 100px;
    height: 100%;
}

.faq-wrapper {
    width: 80%;
    background-color: $main-color;

    overflow: hidden;
    font-size: 22px;
}

.faq-title {
    color: white;
    cursor: pointer;
    background-color: $main-color;
    display: flex;
    min-height: 45px;
    padding: 0px 50px;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
    transition: 0.4s;
    border-bottom: 1px solid black;

    &:hover {
        background-color: var(--theme-button-background);
    }
}

.faq-description {
    padding: 0px 50px;
    background-color: black;
    min-height: 70px;
    display: flex;
    color: white;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
    transition: 1s;
    text-align: justify;
    -webkit-animation: fade-in 1s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    animation: fade-in 1s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@-webkit-keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@media screen and (max-width: $mobile), screen and (max-width: $tablet) {
    .faq-wrapper {
        width: 100%;
    }

    .faq-container {
        height: 100%;
    }

    .faq-title {
        padding: 10px 25px;
    }

    .faq-description {
        padding: 10px 25px;
    }
}
