.light {
    --theme-color: #a80000;
    --theme-page-background: #fff;
    --theme-page-text: #272427;

    --theme-navbar-background: #bebebe;

    --theme-dropdown-background: #27262c;

    --theme-button-border: #000000;
    --theme-button-color: black;
    --theme-button-background: #ea008b;

    --theme-modal-background: #6703fe;

    --theme-drop-shadow: #15171f;

    --theme-sticker-background: #fbd930;

    transition: 2s;
}

.dark {
    --theme-color: #0000a8;
    --theme-page-background: black;
    --theme-page-text: black;

    --theme-navbar-background: transparent;

    --theme-dropdown-background: #27262c;

    --theme-button-border: black;
    --theme-button-color: black;
    --theme-button-background: #fbd930;

    --theme-link-hover-color: #fbd930;

    --theme-modal-background: #6703fe;

    --theme-drop-shadow: #313030;

    --theme-sticker-background: #fbd930;
    transition: 2s;
}
