@import "src/styles/common.scss";

.modal-container {
    position: fixed;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    background-color: rgba(0,0,0,0.4);
    z-index: 1000;
}

.modal-content {
    max-height: 100%;
    max-width: 50%;
    padding: 40px;
    color: white;
    background-color: var(--theme-modal-background);
    filter: drop-shadow(10px 10px 0px var(--theme-drop-shadow));
}

.modal-container-x {
    position: absolute;
    right: 15px;
    top: 5px;
    cursor: pointer;
}

@media screen and (max-width: $mobile), screen and (max-width: $tablet) {
    .modal-content {
        font-size: 20px
    }
}
