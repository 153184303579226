@import "src/styles/common.scss";

.sticker-container {
    display: flex;
    width: 100%;
    margin-top: 50px;
    height: 200px;
    filter: drop-shadow(0px 2px 2px black);
    .sticker {
        display: flex;
        flex: 1 0 21%;
        margin: 10px;
        height: 175px;

        &-description {
            font-size: 40px;
        }
    }
    .sticker-1 {
        animation: float-sticker-1 6s ease-in-out infinite;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 40px;
        color: black;

        width: 100%;
        height: 140%;
        margin-top: 70px;
        margin-right: -5px;
        background-color: var(--theme-sticker-background);
        -webkit-clip-path: polygon(25% 5%, 75% 5%, 100% 50%, 75% 95%, 25% 95%, 0% 50%);
        clip-path: polygon(25% 5%, 75% 5%, 100% 50%, 75% 95%, 25% 95%, 0% 50%);
        .sticker-text {
            border: none;
            background-color: transparent;
            margin: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }
    .sticker-2 {
        animation: float-sticker-2 6s ease-in-out infinite;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 40px;
        color: black;

        width: 100%;
        height: 140%;
        margin-right: -5px;
        margin-top: 20px;
        background-color: var(--theme-sticker-background);
        -webkit-clip-path: polygon(25% 5%, 75% 5%, 100% 50%, 75% 95%, 25% 95%, 0% 50%);
        clip-path: polygon(25% 5%, 75% 5%, 100% 50%, 75% 95%, 25% 95%, 0% 50%);
        .sticker-text {
            border: none;
            background-color: transparent;
            margin: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }
    .sticker-3 {
        animation: float-sticker-3 6s ease-in-out infinite;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 40px;
        color: black;

        width: 100%;
        height: 140%;
        margin-top: 100px;
        margin-left: -5px;
        background-color: var(--theme-sticker-background);
        -webkit-clip-path: polygon(25% 5%, 75% 5%, 100% 50%, 75% 95%, 25% 95%, 0% 50%);
        clip-path: polygon(25% 5%, 75% 5%, 100% 50%, 75% 95%, 25% 95%, 0% 50%);
        .sticker-text {
            border: none;
            background-color: transparent;
            margin: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }
    .sticker-4 {
        animation: float-sticker-4 6s ease-in-out infinite;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 40px;
        color: black;

        width: 100%;
        height: 140%;
        margin-top: 50px;
        margin-left: -5px;
        background-color: var(--theme-sticker-background);
        -webkit-clip-path: polygon(25% 5%, 75% 5%, 100% 50%, 75% 95%, 25% 95%, 0% 50%);
        clip-path: polygon(25% 5%, 75% 5%, 100% 50%, 75% 95%, 25% 95%, 0% 50%);
        .sticker-text {
            border: none;
            background-color: transparent;
            margin: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }
}

.test {
    width: 100%;
}

.row {
    width: 100%;
}

@keyframes float-sticker-1 {
    0% {
        transform: rotate(-5deg) translatey(0px);
    }
    50% {
        transform: rotate(-3deg) translatey(-10px);
    }
    100% {
        transform: rotate(-5deg) translatey(0px);
    }
}

@keyframes float-sticker-2 {
    0% {
        transform: rotate(5deg) translatey(-10px);
    }
    50% {
        transform: rotate(3deg) translatey(0px);
    }
    100% {
        transform: rotate(5deg) translatey(-10px);
    }
}

@keyframes float-sticker-3 {
    0% {
        transform: rotate(5deg) translatey(0px);
    }
    50% {
        transform: rotate(3deg) translatey(-10px);
    }
    100% {
        transform: rotate(5deg) translatey(0px);
    }
}

@keyframes float-sticker-4 {
    0% {
        transform: rotate(-5deg) translatey(-10px);
    }
    50% {
        transform: rotate(-3deg) translatey(0px);
    }
    100% {
        transform: rotate(-5deg) translatey(-10px);
    }
}

.sticker-image {
    position: absolute;
    max-height: 90px;
    top: -44px;
    max-width: 120px;
}

.sticker-image-burn {
    position: absolute;
    width: 30%;
    top: -70px;
    max-width: 120px;
}

.sticker-text {
    font-family: "Gotham Rounded", sans-serif;
    font-size: 20px;
}

.feature-container {
    display: flex;
    width: 100%;
    margin-top: 100px;
    height: 300px;
    filter: drop-shadow(0px 2px 2px black);
    .feature {
        display: flex;
        flex: 1 0 21%;
        margin: 10px;
        height: 175px;
    }
    .feature-1 {
        animation: float-feature-1 6s ease-in-out infinite;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 40px;

        width: 100%;
        height: 150%;
        margin-top: 50px;
        background-color: white;
        -webkit-clip-path: polygon(25% 5%, 75% 5%, 100% 50%, 75% 95%, 25% 95%, 0% 50%);
        clip-path: polygon(25% 5%, 75% 5%, 100% 50%, 75% 95%, 25% 95%, 0% 50%);
        .feature-text {
            border: none;
            background-color: transparent;
        }
    }
    .feature-2 {
        animation: float-feature-2 6s ease-in-out infinite;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 40px;

        width: 100%;
        height: 150%;
        background-color: white;
        -webkit-clip-path: polygon(25% 5%, 75% 5%, 100% 50%, 75% 95%, 25% 95%, 0% 50%);
        clip-path: polygon(25% 5%, 75% 5%, 100% 50%, 75% 95%, 25% 95%, 0% 50%);
        .feature-text {
            border: none;
            background-color: transparent;
        }
    }
    .feature-3 {
        animation: float-feature-3 6s ease-in-out infinite;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 40px;

        width: 100%;
        height: 150%;
        margin-top: 50px;
        margin-left: 5px;
        background-color: white;
        -webkit-clip-path: polygon(25% 5%, 75% 5%, 100% 50%, 75% 95%, 25% 95%, 0% 50%);
        clip-path: polygon(25% 5%, 75% 5%, 100% 50%, 75% 95%, 25% 95%, 0% 50%);
        .feature-text {
            border: none;
            background-color: transparent;
        }
    }
    .feature-4 {
        animation: float-feature-4 6s ease-in-out infinite;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 40px;

        width: 100%;
        height: 150%;
        margin-top: 30px;
        margin-left: 5px;
        background-color: white;
        -webkit-clip-path: polygon(25% 5%, 75% 5%, 100% 50%, 75% 95%, 25% 95%, 0% 50%);
        clip-path: polygon(25% 5%, 75% 5%, 100% 50%, 75% 95%, 25% 95%, 0% 50%);
        .feature-text {
            border: none;
            background-color: transparent;
        }
    }
}

@keyframes float-feature-1 {
    0% {
        transform: rotate(5deg) translatey(-10px);
    }
    50% {
        transform: rotate(3deg) translatey(0px);
    }
    100% {
        transform: rotate(5deg) translatey(-10px);
    }
}

@keyframes float-feature-2 {
    0% {
        transform: rotate(-5deg) translatey(10px);
    }
    50% {
        transform: rotate(-3deg) translatey(0px);
    }
    100% {
        transform: rotate(-5deg) translatey(10px);
    }
}

@keyframes float-feature-3 {
    0% {
        transform: rotate(-5deg) translatey(-10px);
    }
    50% {
        transform: rotate(-3deg) translatey(0px);
    }
    100% {
        transform: rotate(-5deg) translatey(-10px);
    }
}

@keyframes float-feature-4 {
    0% {
        transform: rotate(5deg) translatey(0px);
    }
    50% {
        transform: rotate(3deg) translatey(-10px);
    }
    100% {
        transform: rotate(5deg) translatey(0px);
    }
}

.feature-text {
    font-family: "Gotham Rounded", sans-serif;
    font-size: 20px;
    color: #301f4f;
    text-align: center;
    margin: 0px 45px;
}

.feature-text-description {
    font-size: 18px;
    font-family: "Gotham Rounded Book", sans-serif;
}

.feature-line {
    border-bottom: 2px solid #301f4f;
    margin-top: 10px;
    margin-bottom: 10px;
}

@media screen and (max-width: $mobile), screen and (max-width: $tablet) {
    .sticker-container {
        position: relative;
        height: auto;
        margin-top: 50px;
    }

    .row {
        margin: 50px 0px;
    }

    .sticker-1,
    .sticker-2,
    .sticker-3,
    .sticker-4,
    .sticker {
        margin-top: 100px !important;
    }

    .feature-container {
        position: relative;
        height: auto;
        margin-bottom: 100px;
    }

    .feature-1,
    .feature-2,
    .feature-3,
    .feature-4,
    .feature {
        margin-top: 100px !important;
    }

    .sticker-image-burn {
        height: 140px;
    }
}
