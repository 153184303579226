@import "src/styles/common.scss";

$button-bg: $main-color;
$cum-color: white;
$button-text-color: #fff;

.button {
    font-family: "Gotham Rounded", sans-serif;
    display: flex;
    position: relative;
    font-size: 16px;
    height: 30px;
    color: black;

    align-items: center;
    border: none;
    cursor: pointer;
    position: relative;
    transition: transform ease-in 0.1s, box-shadow ease-in 0.25s;
    border: 3px solid transparent;
    width: auto;
    padding: 0px 20px;
    justify-content: center;
    margin: 0px 20px;
    border-radius: 40px;

    &:focus {
        outline: 0;
    }

    &:active,
    &:hover {
        transform: scale(0.9);
        background-color: black;
        box-shadow: 0 2px 25px rgba(255, 0, 130, 0.2);
        color: $main-color;
        transition: 1s;
    }

    &--active {
        font-family: "Gotham Rounded", sans-serif;
        color: var(--theme-sticker-background);
        padding: 3px 10px 3px 10px;
        justify-content: center;
        transition: 1s;

        padding: 0px 20px;
        background-color: black;
        color: $main-color;
    }
}
