@import "src/styles/common.scss";

.component-container {
    padding-top: 200px;
    padding-bottom: 100px;
}

.roadmap {
    &__container {
        margin-top: 75px;
    }

    &_wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    &_blocks {
        display: flex;
        min-height: 550px;
    }

    &_block {
        display: flex;
        flex: 25%;
        flex-direction: column;
        text-align: center;
        transition: 1s;
        -webkit-animation: fade-in 3s cubic-bezier(0.39, 0.575, 0.565, 1) both;
        animation: fade-in 3s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    }

    &_logo-on {
        width: 50%;
        margin: 0 auto;
        margin-bottom: 50px;
        animation: float-banana 6s infinite;
    }

    &_logo-off {
        width: 50%;
        margin: 0 auto;
        margin-bottom: 50px;
        filter: grayscale(1);
        &:hover {
            -webkit-animation: shake-lr 2s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
            animation: shake-lr 2s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
        }
    }

    &_title {
        display: flex;
        justify-content: center;
        font-size: 20px;
        height: 30px;
        font-family: "Gotham Rounded", sans-serif;
    }

    &_description {
        margin-top: 30px;
        font-size: 16px;
        text-align: left;
        padding: 0 15px;
        justify-content: center;
        align-items: center;
        display: flex;
        position: relative;
        ul {
            margin-left: 25px;
        }

        li {
            margin: 5px 0;
        }

        ul li::before {
            content: "\2022"; /* Add content: \2022 is the CSS Code/unicode for a bullet */
            color: var(--theme-button-background); /* Change the color */
            font-weight: bold; /* If you want it to be bold */
            display: inline-block; /* Needed to add space between the bullet and the text */
            width: 1em; /* Also needed for space (tweak if needed) */
            margin-left: -1em; /* Also needed for space (tweak if needed) */
        }
    }

    &__line-container {
        &-arrow {
            width: 50px;
            cursor: pointer;
        }
    }

    &__button-wrapper {
        display: flex;
        width: 100%;
        justify-content: space-evenly;
        align-items: center;
    }

    &__button {
        font-family: "Gotham Rounded", sans-serif;
        cursor: pointer;
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        max-width: 500px;
        width: 250px;
        font-size: 20px;
        padding-top: 5px;
        padding-bottom: 5px;
        letter-spacing: 1px;
        height: 50px;

        font-weight: bold;
        background-color: var(--theme-button-background);
        color: var(--theme-button-color);
        flex-wrap: wrap;
        color: black;
        text-align: center;
        &:hover {
            background-color: #f83821;
            filter: drop-shadow(0px 0px 15px #f83821);
        }
        transition: 1s;
    }
}

@keyframes float-banana {
    0% {
        transform: translatey(-15px);
    }
    50% {
        transform: translatey(15px);
    }
    100% {
        transform: translatey(-15px);
    }
}

@-webkit-keyframes shake-lr {
    0%,
    100% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
    }
    10% {
        -webkit-transform: rotate(8deg);
        transform: rotate(8deg);
    }
    20%,
    40%,
    60% {
        -webkit-transform: rotate(-10deg);
        transform: rotate(-10deg);
    }
    30%,
    50%,
    70% {
        -webkit-transform: rotate(10deg);
        transform: rotate(10deg);
    }
    80% {
        -webkit-transform: rotate(-8deg);
        transform: rotate(-8deg);
    }
    90% {
        -webkit-transform: rotate(8deg);
        transform: rotate(8deg);
    }
}
@keyframes shake-lr {
    0%,
    100% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
    }
    10% {
        -webkit-transform: rotate(8deg);
        transform: rotate(8deg);
    }
    20%,
    40%,
    60% {
        -webkit-transform: rotate(-10deg);
        transform: rotate(-10deg);
    }
    30%,
    50%,
    70% {
        -webkit-transform: rotate(10deg);
        transform: rotate(10deg);
    }
    80% {
        -webkit-transform: rotate(-8deg);
        transform: rotate(-8deg);
    }
    90% {
        -webkit-transform: rotate(8deg);
        transform: rotate(8deg);
    }
}

@-webkit-keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@media screen and (max-width: $mobile), screen and (max-width: $desktop) {
    .component-container {
        height: 100%;
    }
    .roadmap {
        &_wrapper {
            flex-direction: column;
        }

        &_blocks {
            flex-direction: column;
            align-items: center;
        }

        &_block {
            margin: 20px 0px;
        }

        &__button-wrapper {
            flex-direction: column;
        }

        &__button {
            margin: 20px 0;
        }

        &__line-container-arrow {
            transform: rotate(90deg);
        }
    }
}
