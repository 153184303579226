$mobile: 420px;
$tablet: 768px;
$desktop: 1220px;
$main-color: #fbd930;

.container {
    margin: auto;
    min-height: calc(100vh - 120px);
}
@media (min-width: 768px) {
    .container {
        width: 750px;
    }
}
@media (min-width: 992px) {
    .container {
        width: 970px;
    }
}
@media (min-width: 1200px) {
    .container {
        width: 1170px;
    }
}

img {
    max-width: 100%;
}

a {
    text-decoration: none;
    color: white;
}

.row {
    display: flex;
    flex-wrap: wrap;
}

.column {
    flex: 50%;
    justify-content: center;
    display: flex;
    align-items: center;
}

h2 {
    display: flex;
    font-family: "Gotham Rounded", sans-serif;
    width: 100%;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 30px;
    font-size: 40px;
    text-align: center;
}

ul {
    padding: 0;
}

.component-container {
    display: flex;
    align-items: center;
    height: calc(100vh);
    flex-direction: column;
    justify-content: center;
}
@media screen and (max-width: $desktop) {
    .column {
        flex: none;
        display: flex;
        width: 100%;
        flex-wrap: wrap;
    }
}
@media screen and (max-width: $mobile), screen and (max-width: $tablet) {
    .row {
        display: grid;
    }

    .container {
        margin-right: 30px;
        margin-left: 30px;
    }

    .component-container {
        height: 100%;
        padding: 50px 0px;
    }
}
