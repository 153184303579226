@import "src/styles/common.scss";

.team {
    &_container {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }

    &_card {
        transition: 0.3s;
        width: 25%;
        border-radius: 5px;
        cursor: pointer;
        margin: 30px;
        justify-content: center;
        align-items: center;
        display: flex;
        flex-direction: column;
    }

    &_image {
        width: 50%;
        object-fit: cover;
        &:hover {
            -webkit-animation: heartbeat 1.5s ease-in-out infinite both;
            animation: heartbeat 1.5s ease-in-out infinite both;
            transition: 1s;
            filter: drop-shadow(0px 0px 5px $main-color);
        }
    }

    &_text {
        display: flex;
        flex-direction: column;
        &-wrapper {
            display: flex;
            flex-direction: column;
            height: 100%;
        }
        &-name {
            font-family: "Gotham Rounded", sans-serif;
            margin: 18px;
            font-size: 14px;
            text-align: center;
        }
        &-title {
            margin: 0px 20px 15px 20px;
            font-size: 16px;
            font-style: italic;
            display: flex;
            min-height: 50px;
            justify-content: center;
            text-align: center;
        }

        &-description {
            margin: 0px 20px 15px 20px;
            font-size: 13px;
            align-items: baseline;
            display: flex;
            min-height: 70px;
        }
    }
}

@-webkit-keyframes heartbeat {
    from {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transform-origin: center center;
        transform-origin: center center;
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
    17% {
        -webkit-transform: scale(1.05);
        transform: scale(1.05);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }

    45% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
}

@keyframes heartbeat {
    from {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transform-origin: center center;
        transform-origin: center center;
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
    17% {
        -webkit-transform: scale(1.05);
        transform: scale(1.05);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }

    45% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
}

@media screen and (max-width: $mobile) {
    .team {
        &_image {
            width: 60%;
        }

        &_card {
            width: 100%;
            margin: 10px;
        }
    }
}
