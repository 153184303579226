@import "src/styles/common.scss";

.socials {
    &__container {
        font-family: "Gotham Rounded", sans-serif;
        position: fixed;
        right: 0;
        top: 30%;
        z-index: 100;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        width: 70px;
        height: 220px;
        color: var(--theme-button-color);
        background-color: var(--theme-sticker-background);
        border-top-left-radius: 40px;
        border-bottom-left-radius: 40px;
        filter: drop-shadow(0px 2px 2px black);
        &:hover {
            background-color: #f83821;
            transition: 1s;
            filter: drop-shadow(0px 0px 15px #f83821);
        }
        transition: 1s;
    }

    &__container--open-wrapper {
        position: relative;
        display: flex;
        height: 100%;
        width: 100%;
    }

    &__container--open {
        font-family: "Gotham Rounded", sans-serif;
        position: fixed;
        right: 0;
        top: 30%;
        background-color: var(--theme-socials-background);
        z-index: 100;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        width: 350px;
        height: 200px;
        color: var(--theme-button-color);
        background-color: var(--theme-sticker-background);
        transition: 1s;
        font-size: 16px;
        overflow: hidden;
        height: 544px;
        border-top-left-radius: 40px;
        border-bottom-left-radius: 40px;
        filter: drop-shadow(0px 2px 2px black);
    }

    &__block {
        font-family: "Gotham Rounded Bold", sans-serif;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        span {
            margin: 5px;
        }
        &--sub-title {
            font-family: "Gotham Rounded", sans-serif;
        }
        &--sub-text {
            font-family: "Gotham Rounded Book", sans-serif;
        }
    }

    &__images-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-left: 50px;
        align-items: center;
        -webkit-animation: slide-in-right 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
        animation: slide-in-right 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
        a {
            display: flex;
        }
        a img {
            margin: 10px;
            width: 50px;
            border-radius: 50%;
            transition: 1s;
            -webkit-animation: slide-in-right 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
            animation: slide-in-right 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
        }
    }

    &__twitter {
        -webkit-animation: slide-in-right 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
        animation: slide-in-right 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    }

    &__text {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        text-align: center;
        cursor: pointer;
        flex-direction: column;
        justify-content: center;
        font-size: 18px;
        font-weight: bold;
        writing-mode: vertical-lr;
        text-orientation: upright;
        span {
            margin: 0px;
        }

        &--open {
            height: 100%;
            display: flex;
            align-items: center;
            text-align: center;
            cursor: pointer;
            flex-direction: column;
            justify-content: center;
            font-size: 16px;
            left: 0;
            width: 50px;
            font-weight: bold;
            writing-mode: vertical-lr;
            text-orientation: upright;
            position: absolute;
            background-color: #f83821;
        }
    }
}

@-webkit-keyframes slide-in-right {
    0% {
        -webkit-transform: translateX(1000px);
        transform: translateX(1000px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
}
@keyframes slide-in-right {
    0% {
        -webkit-transform: translateX(1000px);
        transform: translateX(1000px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
}

@media screen and (max-width: $tablet) {
    .socials {
        &__container {
            bottom: 0;
            top: unset;
            left: 0;
            width: 100%;
            height: 50px;
            border-top-left-radius: 40px;
            border-top-right-radius: 40px;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }

        &__text {
            writing-mode: unset;
        }

        &__container--open {
            top: unset;
            bottom: 0;
            right: unset;
            width: 100%;
            height: 80vh;
            border-top-left-radius: 40px;
            border-top-right-radius: 40px;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }

        &__text--open {
            left: unset;
            top: 0;
            writing-mode: unset;
            height: 50px;
            width: 100%;
        }

        &__images-container {
            margin: 50px 0px 0px 0px;
            -webkit-animation: slide-in-bottom 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
            animation: slide-in-bottom 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
            a img {
                margin: 10px;
                width: 50px;
                border-radius: 50%;
                transition: 1s;
                -webkit-animation: slide-in-bottom 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
                animation: slide-in-bottom 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
            }
        }

        &__twitter {
            -webkit-animation: slide-in-bottom 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
            animation: slide-in-bottom 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
        }
    }
}

@-webkit-keyframes slide-in-bottom {
    0% {
        -webkit-transform: translateY(1000px);
        transform: translateY(1000px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
}
@keyframes slide-in-bottom {
    0% {
        -webkit-transform: translateY(1000px);
        transform: translateY(1000px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
}

@media screen and (max-width: $tablet) {
    .socials {
        &__container {
            display: none;
        }
    }
}