@import "src/styles/common.scss";

.balance {
    &__container {
        font-family: "Gotham Rounded", sans-serif;
        position: fixed;
        left: 0;
        top: 30%;
        z-index: 100;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        width: 70px;
        height: 220px;
        color: var(--theme-button-color);
        background-color: var(--theme-sticker-background);
        border-top-right-radius: 40px;
        border-bottom-right-radius: 40px;
        filter: drop-shadow(0px 2px 2px black);
        &:hover {
            background-color: #f83821;
            filter: drop-shadow(0px 0px 15px #f83821);
        }
        transition: 1s;
    }

    &__container {
        &--open-wrapper {
            position: relative;
            display: flex;
            height: 100%;
            width: 100%;
            flex-direction: column;
            justify-content: center;
        }

        &--open {
            font-family: "Gotham Rounded", sans-serif;
            position: fixed;
            left: 0;
            top: 30%;
            background-color: var(--theme-socials-background);
            z-index: 100;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            width: 150px;
            height: 200px;
            color: var(--theme-button-color);
            background-color: var(--theme-sticker-background);
            font-size: 16px;
            overflow: hidden;
            transition: 1s;
            border-top-right-radius: 40px;
            border-bottom-right-radius: 40px;
            filter: drop-shadow(0px 2px 2px black);
        }
    }
    &__text {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        text-align: center;
        cursor: pointer;
        flex-direction: column;
        justify-content: center;
        font-size: 20px;
        font-weight: bold;
        writing-mode: vertical-lr;
        text-orientation: upright;
        span {
            margin: 0px;
        }

        &--open {
            height: 100%;
            display: flex;
            align-items: center;
            text-align: center;
            cursor: pointer;
            flex-direction: column;
            justify-content: center;
            font-size: 16px;
            right: 0;
            width: 50px;
            font-weight: bold;
            writing-mode: vertical-lr;
            text-orientation: upright;
            position: absolute;
            background-color: #f83821;
        }
    }

    &__block {
        font-family: "Gotham Rounded Bold", sans-serif;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        width: 100px;
        justify-content: center;
        -webkit-animation: slide-in-right 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
        animation: slide-in-right 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;

        span {
            margin: 5px;
        }
        &--sub-title {
            font-family: "Gotham Rounded", sans-serif;
        }
        &--sub-text {
            font-family: "Gotham Rounded Book", sans-serif;
        }
    }

    &__connect {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        text-align: center;
        cursor: pointer;
        flex-direction: column;
        justify-content: center;
        font-size: 18px;
        font-weight: bold;
        writing-mode: vertical-lr;
        text-orientation: upright;
        span {
            margin: 0px;
        }
    }
}

.ape-in-button {
    font-family: "Gotham Rounded", sans-serif;
    cursor: pointer;
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    width: 80px;
    font-size: 16px;
    padding-top: 5px;
    padding-bottom: 5px;
    letter-spacing: 1px;
    height: 20px;
    font-weight: bold;
    background-color: var(--theme-button-background);
    color: var(--theme-button-color);
    flex-wrap: wrap;
    margin-left: 10px;
    color: black;
    text-align: center;
    box-shadow: 0px 5px 10px black;
    &-uni {
        fill: #ff007a;
        &:hover {
            fill: black;
        }
    }
    &:hover {
        box-shadow: none;
        background-color: #f83821;
        filter: drop-shadow(0px 0px 2px black);
        svg {
            fill: black;
            transition: 1s;
        }
    }
    transition: 1s;
}

span {
    margin: 5px;
}

@-webkit-keyframes slide-in-right {
    0% {
        -webkit-transform: translateX(-1000px);
        transform: translateX(-1000px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
}
@keyframes slide-in-right {
    0% {
        -webkit-transform: translateX(-1000px);
        transform: translateX(-1000px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
}

@media screen and (max-width: $tablet) {
    .ape-in-button {
        margin: 0px;
    }
    .balance {
        &__container {
            bottom: 0;
            top: unset;
            left: 0;
            width: 100%;
            height: 50px;
            border-top-left-radius: 40px;
            border-top-right-radius: 40px;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            &--open-wrapper {
                align-items: center;
            }
        }

        &__connect {
            writing-mode: unset;
        }

        &__container--open {
            display: flex;
            top: unset;
            bottom: 0;
            right: unset;
            width: 100%;
            justify-content: center;
            align-content: center;
            height: 300px;
            border-top-left-radius: 40px;
            border-top-right-radius: 40px;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }

        &__text--open {
            left: unset;
            top: 0;
            writing-mode: unset;
            height: 50px;
            width: 100%;
        }
    }
}
