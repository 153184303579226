@import "src/styles/common.scss";

.header-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.row {
    width: 100%;
}

.ape-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.ape {
    max-height: 300px;
    position: absolute;
    bottom: 22%;
    right: 52%;
    z-index: 2;
}

.honeybee {
    height: 100%;
    left: 10%;
    bottom: -10%;
    animation: float 6s ease-in-out infinite;
}
@keyframes float {
    0% {
        transform: rotate(-1deg);
    }
    50% {
        transform: rotate(1deg);
    }
    100% {
        transform: rotate(-1deg);
    }
}

.moon {
    position: absolute;
    max-width: 250px;
    left: 40%;
    top: 10%;
    filter: drop-shadow(0px 0px 100px var(--theme-sticker-background));
    -webkit-animation: heartbeat 2s ease-in-out infinite both;
    animation: heartbeat 2s ease-in-out infinite both;
}

.rocket {
    position: absolute;
    height: 75px;
    right: 45%;
    top: 45%;
    z-index: 1;
    animation: moveTrain 10s linear infinite;
}

@keyframes moveTrain {
    0% {
        transform: translate(-155px, -129px);
    }
    40% {
        transform: translate(-60px, 40px) rotate(360deg);
    }
    60% {
        transform: translate(160px, -120px);
    }
    80% {
        transform: translate(-120px, 100px) rotate(360deg);
    }
    100% {
        transform: translate(-155px, -129px);
    }
}

@-webkit-keyframes rotate-center {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes rotate-center {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.banana-0 {
    position: absolute;
    z-index: 1;
    height: 30px;
    right: 23%;
    bottom: 34%;
    animation: float-banana-1 6s infinite;
}

.saber {
    position: absolute;
    z-index: 1;
    height: 120px;
    right: 15%;
    bottom: 10%;
    animation: float-saber 6s infinite;
}

.logo {
    max-width: 400px;
}

@keyframes float-ape {
    0% {
        transform: translatey(0px);
    }
    50% {
        transform: translatey(-20px);
    }
    100% {
        transform: translatey(0px);
    }
}

@keyframes float-banana-1 {
    0% {
        transform: translatey(0px) translateX(0px) rotateZ(-100deg);
    }
    50% {
        transform: translatey(30px) translateX(40px) rotateZ(-60deg);
    }
    100% {
        transform: translatey(0px) translateX(0px) rotateZ(-100deg);
    }
}

@keyframes float-saber {
    0% {
        transform: translatey(0px) rotate(60deg);
    }
    50% {
        transform: translatey(-30px) rotate(30deg);
    }
    100% {
        transform: translatey(0px) rotate(60deg);
    }
}

.banana-1 {
    position: absolute;
    z-index: 1;
    height: 30px;
    right: 19%;
    top: 4%;
    animation: float-banana-2 6s infinite;
}

.uniswap-logo {
    height: 40px;
}

@keyframes float-banana-2 {
    0% {
        transform: translatey(0px) rotate(210deg) rotateZ(-20deg);
    }
    50% {
        transform: translatey(-30px) rotate(180deg) rotateZ(30deg);
    }
    100% {
        transform: translatey(0px) rotate(210deg) rotateZ(-20deg);
    }
}

.banana-2 {
    position: absolute;
    z-index: 1;
    height: 30px;
    left: 40%;
    bottom: 70%;
    animation: float-banana-3 6s infinite;
}

@keyframes float-banana-3 {
    0% {
        transform: translatey(0px) rotate(40deg) rotateZ(100deg);
    }
    50% {
        transform: translatey(-30px) rotate(60deg) rotateZ(110deg);
    }
    100% {
        transform: translatey(0px) rotate(40deg) rotateZ(100deg);
    }
}

.header-welcome-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    a {
        margin: 0px 20px;
    }
}

.welcome-message {
    font-family: "Gotham Rounded", sans-serif;
    font-size: 30px;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 15px;
    justify-content: center;
    align-items: center;
}

.welcome-description {
    font-family: "Gotham Rounded Book", sans-serif;
    font-size: 20px;
    text-align: center;
    margin-top: 15px;
    margin-bottom: 30px;
    justify-content: center;
    align-items: center;
}
.saving-bees {
    font-size: 16px;
    text-align: center;
    margin-top: 15px;
    margin-bottom: 30px;
    justify-content: center;
    align-items: center;
}
.token-contract {
    font-family: "Gotham Rounded", sans-serif;
    color: $main-color;
    font-size: 22px;
    margin-bottom: 30px;
    text-align: center;
    display: flex;
    flex-direction: column;
}

.token-address {
    font-family: "Gotham Rounded", sans-serif;
    font-size: 20px;
    color: black;
}

.component-container {
    height: calc(100vh - 70px);
}

.ape-in-button {
    font-family: "Gotham Rounded", sans-serif;
    cursor: pointer;
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px 10px;
    border-radius: 20px;
    max-width: 500px;
    width: 250px;
    font-size: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
    letter-spacing: 1px;
    height: 50px;

    font-weight: bold;
    background-color: var(--theme-button-background);
    color: var(--theme-button-color);
    flex-wrap: wrap;
    color: black;
    text-align: center;
    box-shadow: 0px 5px 10px black;
    &-uni {
        fill: #ff007a;
        &:hover {
            fill: black;
        }
    }
    &:hover {
        box-shadow: none;
        background-color: #f83821;
        filter: drop-shadow(0px 0px 2px black);
        svg {
            fill: black;
            transition: 1s;
        }
    }
    transition: 1s;
}

.banana-closed {
    height: 74px;
    left: -14px;
    display: flex;
    position: absolute;
}

.button-wrapper {
    display: flex;
}

.banana-opened {
    height: 74px;
    left: -46px;
    display: flex;
    position: absolute;
}

.button-slippage {
    font-size: 9px;
}

@keyframes heartbeat {
    0% {
        transform: scale(1) rotateZ(20deg);
    }
    50% {
        transform: scale(1.05) rotateZ(20deg);
    }

    100% {
        transform: scale(1) rotateZ(20deg);
    }
}

@media screen and (max-width: $desktop) {
    .token-address {
        font-size: 10px;
        flex-wrap: wrap;
        display: flex;
        justify-content: center;
    }

    .ape-wrapper {
        margin-top: 150px;
    }

    .honeybee {
        width: 70%;
    }

    .moon {
        position: absolute;
        left: 35%;
        height: 200px;
        &:hover {
            -webkit-animation: heartbeat 1.5s ease-in-out infinite both;
            animation: heartbeat 1.5s ease-in-out infinite both;
        }
    }

    .rocket {
        position: absolute;
        height: 40px;
        top: 100px;
        left: 45%;
    }

    @keyframes circle {
        0% {
            transform: rotate(0deg) translate(-110px) rotate(45deg);
        }
        100% {
            transform: rotate(360deg) translate(-110px) rotate(45deg);
        }
    }

    .banana-0 {
        left: 60%;
        bottom: 10%;
    }

    .banana-1,
    .banana-2 {
        display: none;
    }

    .component-container {
        height: 100%;
        padding-top: 100px;
    }

    .button-wrapper {
        flex-direction: column;
        a {
            margin: 20px 0px;
        }
    }
}
