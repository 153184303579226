@import "src/styles/common.scss";

.tokenomics-container {
    display: flex;
    position: relative;
    width: 100%;
    margin-top: 50px;
    justify-content: center;
}

.row {
    width: 100%;
    position: relative;
}

.tokenomics-title {
    display: flex;
    font-family: "Gotham Rounded", sans-serif;
    width: 100%;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 30px;
}

.tokenomics {
    flex: 1 0 50%;
    justify-content: center;
    display: flex;
    align-items: center;
}

.tokenomics-column-1 {
    flex: 0 0 40%;
    font-size: 20px;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    filter: drop-shadow(0px 1px 2px black);
    :nth-child(1) {
        background-color: var(--theme-sticker-background);
    }
    :nth-child(2) {
        background-color: white;
    }
    :nth-child(3) {
        background-color: var(--theme-sticker-background);
    }
    :nth-child(4) {
        background-color: white;
    }
    :nth-child(5) {
        background-color: var(--theme-sticker-background);
    }
}

.tokenomics-column-2 {
    flex: 0 0 40%;
    font-size: 20px;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-left: 20px;
    filter: drop-shadow(0px 1px 2px black);
    :nth-child(1) {
        background-color: white;
    }
    :nth-child(2) {
        background-color: var(--theme-sticker-background);
    }
    :nth-child(3) {
        background-color: white;
    }
    :nth-child(4) {
        background-color: var(--theme-sticker-background);
    }
    :nth-child(5) {
        background-color: white;
    }
}

.tokenomics-row {
    font-size: 20px;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 82%;
    filter: drop-shadow(0px 1px 2px black);
    :nth-child(1) {
        width: 100%;
        background-color: white;
    }
}

.tokenomics-row1 {
    font-size: 20px;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 82%;
    filter: drop-shadow(0px 1px 2px black);
    :nth-child(1) {
        height: 62px;
        align-items: center;
        display: flex;
        justify-content: center;
        width: 100%;
        background-color: $main-color;
    }
}

.tokenomic {
    font-family: "Gotham Rounded", sans-serif;
    border-radius: 15px;
    color: #301f4f;
    margin: 5px;
    width: 100%;
    height: 62px;
    margin: 10px 25px;
    align-items: center;
    display: flex;
    justify-content: center;
}

.tokenomic-long {
    font-family: "Gotham Rounded", sans-serif;
    border-radius: 15px;
    color: #301f4f;
    margin: 5px;
    width: 100%;
    height: 100%;
    margin: 10px 25px;
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;

    &__description {
        font-size: 16px;
    }
}

.ape-with-bananas {
    position: absolute;
    height: 300px;
    right: 0%;
    bottom: 11%;
    margin-top: 120px;
}

.eth-explorer {
    font-family: "Gotham Rounded", sans-serif;
    display: flex;
    width: 100%;
    justify-content: left;
    font-size: 14px;
    margin-top: 20px;
    margin-bottom: 20px;
    color: black;
    text-align: left;
}

@media screen and (max-width: $mobile), screen and (max-width: $tablet) {
    .tokenomics-container {
        margin-top: 125px;
    }

    .ape-with-bananas {
        display: none;
    }

    .tokenomic {
        font-size: 16px;
    }

    .tokenomic-long {
        font-size: 16px;

        &__description {
            font-size: 14px;
        }
    }

    .eth-explorer {
        justify-content: flex-start;
    }

    .tokenomics-column-2 {
        margin-left: 0px;
    }

    .tokenomics-row {
        width: 100%;
    }

    .tokenomics-row1 {
        width: 100%;
    }
}
